* {
  /* Remove tap highlight: */
  /* https://css-tricks.com/snippets/css/remove-gray-highlight-when-tapping-links-in-mobile-safari/ */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-size-adjust: none;
}
.scrollable {
  /* Enable momentum scrolling on iOS devices */
  -webkit-overflow-scrolling: touch;
  overflow: auto;
}
.momentum-scroll-fix,
.momentum-scroll-fix * {
  /* Enable momentum scrolling on iOS devices */
  -webkit-overflow-scrolling: touch;
}

a {
  color: #03a9f4;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
html {
  margin: 0;
  font-family: "Roboto", sans-serif;
  height: 100%;
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
body,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
body {
  height: 100%;
  min-height: 100%;
  font-size: 15px;
  line-height: 24px;
  background-color: lightgray;
  /* Disables scroll bounce on body and makes the element with actual scrolling content scroll bounce instead. */
  overflow: auto;
}

/* MUI-workaround --- restore old behavior before https://github.com/callemall/material-ui/pull/5014/files */
td {
  overflow: hidden;
}

/* MUI-fix, icon alignment */
button {
  vertical-align: middle;
}

.rotate {
  -webkit-animation-name: rotate;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: rotate;
  -moz-animation-duration: 1s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  animation-name: rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(360deg);
  }
  to {
    -webkit-transform: rotate(0deg);
  }
}

@-moz-keyframes rotate {
  from {
    -moz-transform: rotate(360deg);
  }
  to {
    -moz-transform: rotate(0deg);
  }
}

@keyframes rotate {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

.pulse {
  -webkit-animation-name: pulse;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  -moz-animation-name: pulse;
  -moz-animation-duration: 2s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: ease-in-out;
  animation-name: pulse;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  opacity: 0.2;
}

@-webkit-keyframes pulse {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

@-moz-keyframes pulse {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

@keyframes pulse {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
